import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
   Box,
   Button,
   Container,
   CssBaseline,
   Grid,
   Link,
   Typography,
} from '@mui/material';

import { displayToaster } from 'utils/utils';
import { ACCOUNT_CONFIRMATION, AlertModalSeverity, PORTAL_ROLES } from 'utils/enums';
import { IAlertToaster } from 'utils/interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { login } from 'store/reducers/user.reducer';

import loginStyles from './styles';

import companyLogo from '../../assets/graphics/logoWithName.png';
import { IitControlledInput } from '../../components/shared-components/iit-controlled-input';
import ENV from '../../config/environment';
import useCommonStyles from '../../style/common';
import { REQUIRED_FIELD } from '../../utils/constants';
import { LoginFormName } from '../../utils/enums';
import { InputType } from '../../utils/enums';
import useIitMe from 'hooks/iit-use-me';

function Copyright() {
   return (
      <Typography variant="body2" color="textSecondary" align="center">
         {'Copyright © '}
         <Link color="inherit" href="https://www.inflightit.com/" target="_blank">
            {ENV.REACT_APP_BRAND}
         </Link>{' '}
         {new Date().getFullYear()}
      </Typography>
   );
}

export default function Login() {
   const navigate = useNavigate();
   const classes = loginStyles();
   const dispatch = useAppDispatch();
   const utilsClasses = useCommonStyles();
   const userState = useAppSelector((state) => state.user);
   const { t } = useTranslation();
   const { control, handleSubmit } = useForm();

   const { getMe } = useIitMe(true);

   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const defaultEmail = searchParams.get('email');
   const confirmationSuccess = searchParams.get('confirmationSuccess');

   const getPages = useCallback(() => {
      if (!userState?.info?.confirmed) {
         return displayToaster({
            message: t('AUTH.USER_UNCONFIRMED'),
            severity: AlertModalSeverity.WARNING,
         } as IAlertToaster);
      }

      if (userState?.info?.isPasswordGenerated) {
         displayToaster({
            message: t('AUTH.PLEASE_CHANGE_YOUR_PASSWORD'),
            severity: AlertModalSeverity.WARNING,
         } as IAlertToaster);
         return navigate('/profile');
      }

      if (userState?.info?.roles?.includes(PORTAL_ROLES.CONCIERGE))
         return navigate('/concierge/scheduler');

      navigate('/users/users-list');
   }, [navigate, t, userState]);

   const submit = handleSubmit(
      useCallback(
         async ({ password, email }) => {
            const req = await dispatch(login({ password, email }));

            if (!req?.payload)
               return displayToaster({
                  message: t('AUTH.USER_UNCONFIRMED'),
                  severity: AlertModalSeverity.WARNING,
               } as IAlertToaster);

            const user = await getMe();
            if (!user?.data?.me?.confirmed) {
               return displayToaster({
                  message: t('AUTH.USER_UNCONFIRMED'),
                  severity: AlertModalSeverity.WARNING,
               } as IAlertToaster);
            }

            navigate('/users/users-list');
         },
         [dispatch, getMe, navigate, t],
      ),
   );

   const loginKeyPress = useCallback(
      (ev: React.KeyboardEvent<HTMLDivElement>) => ev.code === 'Enter' && submit(),
      [submit],
   );

   useEffect(() => {
      switch (confirmationSuccess) {
         case ACCOUNT_CONFIRMATION.CONFIRMED:
            displayToaster({
               message: t('AUTH.CONFIRMED_WITH_SUCCESS'),
               severity: AlertModalSeverity.SUCCESS,
            } as IAlertToaster);
            break;
         case ACCOUNT_CONFIRMATION.NOT_CONFIRMED:
            displayToaster({
               message: t('AUTH.COULD_NOT_CONFIRM'),
               severity: AlertModalSeverity.ERROR,
            } as IAlertToaster);
            break;
         default:
            break;
      }
      if (!userState?.info) return;
      getPages();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userState]);

   return (
      <Container component="main" maxWidth="sm" className={utilsClasses.displayHeight}>
         <Grid
            className={utilsClasses.height100}
            container
            justifyContent="center"
            alignItems="center"
         >
            <CssBaseline />
            <div className={classes.paper}>
               <img src={companyLogo} alt="Logo" className={classes.logo} />
               <Box className={classes.form}>
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <IitControlledInput
                           name={LoginFormName.EMAIL}
                           label={t('GENERAL.EMAIL')}
                           defaultValue={defaultEmail ?? ''}
                           control={control}
                           className={classes.inputs}
                           rules={{
                              required: t('GENERAL.REQUIRED_FIELD') as string,
                              pattern: {
                                 value: ENV.REACT_APP_EMAIL_REGEX,
                                 message: t('GENERAL.INVALID_EMAIL_ADDRESS') as string,
                              },
                           }}
                           onKeyPress={loginKeyPress}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <IitControlledInput
                           name={LoginFormName.PASSWORD}
                           label={t('GENERAL.PASSWORD')}
                           type={InputType.PASSWORD}
                           defaultValue=""
                           control={control}
                           className={classes.inputs}
                           rules={REQUIRED_FIELD}
                           onKeyPress={loginKeyPress}
                        />
                     </Grid>
                  </Grid>
                  <Button
                     disableElevation
                     size="large"
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                     onClick={submit}
                  >
                     {t('GENERAL.LOGIN')}
                  </Button>
               </Box>
            </div>
            <Box padding={5}>
               <Copyright />
            </Box>
         </Grid>
      </Container>
   );
}
