import React, { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useIitMe from 'hooks/iit-use-me';
import { useAppSelector } from 'store';
import { NAVIGATION_PATHS } from 'utils/routes';
import { homeRedirects } from './home.utils';
import Splash from 'components/shared-components/iit-splash';

export default function Home() {
   useIitMe();
   const navigate = useNavigate();
   const { access_token, info: USER } = useAppSelector((state) => state?.user);

   useEffect(() => {
      if (!access_token) navigate(`/${NAVIGATION_PATHS.LOGIN}`);
      if (!USER) return;
      navigate(homeRedirects(USER?.roles));
   }, [access_token, USER, navigate]);

   return (
      <Suspense fallback={<Splash />}>
         <></>
      </Suspense>
   );
}
