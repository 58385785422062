import React, { Suspense, useState, useEffect, useMemo, useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Box, Container, Theme, useMediaQuery } from '@mui/material';

import MobileNavigation from 'components/mobile-navigation';
import { IitPubLoading } from 'components/shared-components/iit-pub-loading';
import { showComponentMobile } from 'components/header/utils';
import Header from 'components/header';
import Sidebar from 'components/sidebar';
import Splash from 'components/shared-components/iit-splash';
import useIitRouting from 'hooks/iit-use-routing';
import { useMeasurable } from 'hooks/useMeasurables';
import { PORTAL_MODULES, STRUCTURE } from 'structure';
import { logout } from 'store/reducers/user.reducer';
import { STATIC_IDENTIFIERS, VALID_TOKEN_REDIRECT } from 'utils/constants';
import { AlertModalSeverity, MEASURABLES_EVENT_TYPES, VIEW_MODES } from 'utils/enums';
import { displayToaster } from 'utils/utils';
import { NAVIGATION_PATHS } from 'utils/routes';
import { IAlertToaster, PortalComponent, PortalPage } from 'utils/interfaces';

import { IitLayout } from '../components/iit-layout';
import { useAppDispatch, useAppSelector } from '../store';
import { collapsedDrawerWidth, drawerWidth, shellStyles } from '../style/common';

function GetQueryParameter() {
   const { search } = useLocation();
   return useMemo(() => new URLSearchParams(search), [search]);
}

const Shell = () => {
   const [components, setComponents] = useState<PortalComponent[]>([]);
   const [isLoading, setIsLoading] = useState(true);
   const [drawerOpen, setDrawerOpen] = useState(true);
   const [page, setPage] = useState<PortalPage>({});

   const dispatch = useAppDispatch();
   const { access_token, info: USER } = useAppSelector((state) => state.user);
   const { pathname } = useLocation();
   const { saveEventHandler } = useMeasurable();
   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
   const { BASE_PATH, navigateTo } = useIitRouting();
   const { t } = useTranslation();

   const queryViewMode = GetQueryParameter();
   const viewMode = queryViewMode.get('viewMode');
   const IS_WEB_VIEW_MODE = viewMode === VIEW_MODES.WEB_VIEW_MODE;

   const classes = shellStyles();

   const CONTAINER_WIDTH = useMemo(() => {
      if (IS_WEB_VIEW_MODE) return '100%';

      return drawerOpen
         ? `calc(100% - ${drawerWidth})`
         : `calc(100% - ${collapsedDrawerWidth})`;
   }, [IS_WEB_VIEW_MODE, drawerOpen]);

   const CONTAINER_SX = useMemo(
      () => ({
         width: {
            sm: CONTAINER_WIDTH,
         },
         float: {
            sm: 'right',
         },
      }),
      [CONTAINER_WIDTH],
   );

   const MENU_OPTIONS = useMemo(() => {
      if (!PORTAL_MODULES) return [];

      return PORTAL_MODULES?.map((item) => {
         return {
            ...item,
            roles: item?.roles?.filter((role) => USER?.roles.includes(role)),
         };
      });
   }, [USER?.roles]);

   const SHOW_BUTTON_NAVIGATION = useMemo(
      () =>
         isMobile &&
         !IS_WEB_VIEW_MODE &&
         showComponentMobile(pathname, IS_WEB_VIEW_MODE, isMobile),
      [IS_WEB_VIEW_MODE, isMobile, pathname],
   );

   const loadViews = useCallback(async () => {
      try {
         const PAGE_CODE = pathname.split('/')[1];
         const MODULE_PAGE_CODE = pathname.split('/')[2];
         const PAGE = STRUCTURE.find(
            (page) => page.path === `/${MODULE_PAGE_CODE || PAGE_CODE}`,
         );
         const MODEL = PORTAL_MODULES.find((model) => model.basePath === BASE_PATH);

         const userToken = access_token;
         if (!PAGE || !PAGE?.roles?.length) {
            displayToaster({
               message: t('GENERAL.ROUTE_DEFINED_WITHOUT_VIEW'),
               severity: AlertModalSeverity.ERROR,
            } as IAlertToaster);
            navigateTo(`/${BASE_PATH}/${MODEL?.redirectPath}`, true);
            return;
         }

         if (!USER?.roles) {
            //todo: roles here
            navigateTo(VALID_TOKEN_REDIRECT);
         }
         const role = PAGE?.roles.find((role: string) => USER?.roles.includes(role));
         if (!role) navigateTo(VALID_TOKEN_REDIRECT);

         if (!userToken) dispatch(logout());

         setPage(PAGE);
         const componentPromises: PortalComponent[] = _.compact(PAGE?.components);
         Promise.all(componentPromises)
            .then(setComponents)
            .then(() => {
               setIsLoading(false);
            });
      } catch (error) {
         console.log(error);
      }
   }, [BASE_PATH, USER, access_token, dispatch, navigateTo, pathname, t]);

   useEffect(() => {
      saveEventHandler({
         identifierLabel: STATIC_IDENTIFIERS.CHANGE_ROUT.NAME,
         identifier: STATIC_IDENTIFIERS.CHANGE_ROUT.ID,
         eventType: MEASURABLES_EVENT_TYPES.COUNT,
         event: STATIC_IDENTIFIERS.CHANGE_ROUT.ID,
         data: {
            url: pathname,
         },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pathname]);

   useEffect(() => {
      if (!USER) return;

      loadViews();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [USER, pathname]);

   if (!access_token) return <Navigate to={`/${NAVIGATION_PATHS.LOGIN}`} />;

   return (
      <React.Fragment>
         <IitPubLoading />

         <Suspense fallback={<Splash />}>
            {!isLoading && (
               <React.Fragment>
                  <Header
                     isWebViewMode={IS_WEB_VIEW_MODE}
                     pageTitle={page?.title}
                     menuOptions={MENU_OPTIONS}
                  />
                  {!isMobile && !IS_WEB_VIEW_MODE && (
                     <Sidebar
                        menuOptions={MENU_OPTIONS}
                        drawerOpen={drawerOpen}
                        setDrawerOpen={setDrawerOpen}
                     />
                  )}

                  <Box
                     display="flex"
                     flexDirection="column"
                     justifyContent="space-between"
                     marginTop={8.875}
                     marginBottom={isMobile && IS_WEB_VIEW_MODE ? 7 : 0}
                  >
                     <Container className={classes.container} maxWidth={false}>
                        <Box display="flex" flexDirection="column" sx={CONTAINER_SX}>
                           <IitLayout
                              portalComponents={components}
                              isWebViewMode={IS_WEB_VIEW_MODE}
                              pageTitle={page?.title}
                           />
                           {SHOW_BUTTON_NAVIGATION && <MobileNavigation />}
                        </Box>
                     </Container>
                  </Box>
               </React.Fragment>
            )}
         </Suspense>
      </React.Fragment>
   );
};

export { Shell };
